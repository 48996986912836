<template style="background-color: #101225; all: unset">
  <!-- start header -->
  <header class="d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-3 col-6 text-left">
          <!--logo start-->
          <a href="index.html" class="logo">
            <img src="img/logo.svg" class="img-fluid" alt="" />
          </a>
          <!--logo end-->
        </div>
        <div class="col-md-9 col-6 col-lg-6">
          <!--menu start-->
          <ul id="menu">
            <li class="nav-item">
              <a href="#home" class="nav-link active">Home</a>
            </li>
            <li class="nav-item">
              <a href="#key" class="nav-link">Key selling</a>
            </li>
            <li class="nav-item">
              <a href="#roadmap" class="nav-link">Roadmap</a>
            </li>
            <li class="nav-item">
              <a href="#howitwork" class="nav-link">How it works</a>
            </li>
            <li class="nav-item">
              <a href="#pricing" class="nav-link">Pricing</a>
            </li>
            <li class="nav-item">
              <a href="#faq" class="nav-link">Faq</a>
            </li>
            <li class="nav-item">
              <a href="#contact" class="nav-link">Contact</a>
            </li>
            <li class="nav-item d-lg-none">
              <a href="#">Login</a>
            </li>
            <li class="nav-item d-lg-none header_btn">
              <a href="#" class="button">sign up</a>
            </li>
          </ul>
          <!--menu end-->

          <!-- menu toggler -->
          <div class="hamburger-menu">
            <span class="line-top"></span>
            <span class="line-center"></span>
            <span class="line-bottom"></span>
          </div>
        </div>
        <div class="col-lg-3 d-none d-lg-block">
          <div class="header_btn d-flex justify-content-end align-items-center">
            <a href="#">Login</a>
            <a href="#" class="button">sign up</a>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- end header -->

  <!-- main area -->
  <main>
    <!-- hero area -->
    <div
      class="hero_wrapper jarallax"
      id="home"
      data-jarallax
      data-video-src="https://www.youtube.com/watch?v=c5A2sCEJWPQ&list=PL1Ae7wNC7eGjc9cdoBz47CfRoN-dfmAuy"
    >
      <div class="container">
        <div class="hero_content text-center">
          <h1>All of the Profit, None of the Work</h1>
          <p>Invest alongside top digital asset managers & funds</p>
          <a href="#howitwork" class="button scroll">how it works</a>
        </div>
      </div>
    </div>
    <!-- key selling area -->
    <div class="key_wrapper" id="key">
      <div class="container">
        <h3 class="title">Key selling points</h3>
        <div class="row">
          <div class="col-lg-4 col-md-6 mt_30">
            <div class="key_box">
              <div class="key_icon">
                <i class="fas fa-window-maximize"></i>
              </div>
              <div class="key_content">
                <h3>
                  Copytrading with just 1 to 100s of accounts, within a second.
                </h3>
                <p>
                  Even for the basic package, orders are usually copied within a
                  second. There are Pro and Unlimited packages that allows
                  copying of orders to many accounts within milliseconds. Simply
                  configure the apikeys, define the risk and start copytrading.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mt_30">
            <div class="key_box">
              <div class="key_icon">
                <i class="fas fa-window-alt"></i>
              </div>
              <div class="key_content">
                <h3>Consume liquidity from multiple exchanges</h3>
                <p>
                  Don’t limit yourself to the liquidity from one exchange,
                  instead trade on multiple at the same time. At this time we
                  only support Bitmex and Bybit, but other exchanges such as
                  FTX, Bitfinex, Binance and many more.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mt_30">
            <div class="key_box">
              <div class="key_icon">
                <i class="fas fa-window-alt"></i>
              </div>
              <div class="key_content">
                <h3>
                  Only a tiny monthly fee, keep all the profits to yourself
                </h3>
                <p>
                  The fee is fixed per month and we don’t ask any percentage of
                  your gains.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- road map area -->
    <div class="road_wrapper" id="roadmap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="title">Roadmap</h2>
            <p class="contact_p">
              strong: Alpha testing Copy orders between your favorite exchanges!
              Currently available: Bitmex.com and bybit.com
            </p>
          </div>
        </div>
      </div>
      <div class="container container_road">
        <div class="row justify-content-end no-gutters">
          <div class="col-lg-6">
            <div class="road_box">
              <h5>November</h5>
              <p>Beta testing: Copy orders from and to FTX.com</p>
            </div>
          </div>
        </div>
        <div class="row no-gutters ltr">
          <div class="col-lg-6">
            <div class="road_box">
              <h5>January 2022</h5>
              <p>Go-live and ability to Copy orders from and to Bitfinex.com</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-end no-gutters">
          <div class="col-lg-6">
            <div class="road_box">
              <h5>February 2022</h5>
              <p>ETH copytrading</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- work area -->
    <div class="work_wrapper" id="howitwork">
      <div class="container">
        <h2 class="title">How it Works</h2>
        <div class="row">
          <div class="col-lg-3 col-sm-6 mt_30">
            <div class="work_box">
              <div class="work_top">
                <div class="work_icon">
                  <i class="fas fa-warehouse"></i>
                  <h3>01</h3>
                </div>
              </div>
              <div class="work_content">
                <h4>Configure a main account</h4>
                <p>
                  Configure a main account that you trade from by providing the
                  apikeys and selecting your favorite exchange
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 mt_30">
            <div class="work_box">
              <div class="work_top">
                <div class="work_icon">
                  <i class="fas fa-warehouse"></i>
                  <h3>02</h3>
                </div>
              </div>
              <div class="work_content">
                <h4>Configure copy accounts</h4>
                <p>
                  Configure copy accounts that will copy all orders from the
                  main account
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 mt_30">
            <div class="work_box">
              <div class="work_top">
                <div class="work_icon">
                  <i class="fas fa-warehouse"></i>
                  <h3>03</h3>
                </div>
              </div>
              <div class="work_content">
                <h4>Start trading</h4>
                <p>Start trading on your main account</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 mt_30">
            <div class="work_box">
              <div class="work_top">
                <div class="work_icon">
                  <i class="fas fa-warehouse"></i>
                  <h3>04</h3>
                </div>
              </div>
              <div class="work_content">
                <h4>Monitor all accounts</h4>
                <p>
                  Monitor all accounts (positions, orders) from one single
                  dashboard
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- pricing area  -->
    <div class="pricing_wrapper" id="pricing">
      <div class="container">
        <div class="title_box">
          <h2 class="title">Pricing</h2>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 mt_30">
            <div class="pricing_box">
              <div class="pricing_name">
                <h4>Basic</h4>
              </div>
              <div class="pricing_rate">
                <h3><span>$</span>10</h3>
                <span>/ per month</span>
              </div>
              <div class="pricing_list">
                <ul>
                  <li>
                    <span><i class="fal fa-check"></i></span>
                    <p>copy to 2 other accounts</p>
                  </li>
                  <li>
                    <span><i class="fal fa-check"></i></span>
                    <p>max 2 seconds delay on order copy</p>
                  </li>
                  <li>
                    <span><i class="fal fa-check"></i></span>
                    <p>high performance AWS deployment</p>
                  </li>
                </ul>
              </div>
              <div class="pricing_btn">
                <a href="#contact" class="button scroll">get started</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mt_30">
            <div class="pricing_box">
              <div class="pricing_name">
                <h4>Pro</h4>
              </div>
              <div class="pricing_rate">
                <h3><span>$</span>250</h3>
                <span>/ per month</span>
              </div>
              <div class="pricing_list">
                <ul>
                  <li>
                    <span><i class="fal fa-check"></i></span>
                    <p>copy to 100+ accounts</p>
                  </li>
                  <li>
                    <span><i class="fal fa-check"></i></span>
                    <p>orders are copied within a second</p>
                  </li>
                  <li>
                    <span><i class="fal fa-check"></i></span>
                    <p>location (US, EUR, ASIA) by choice</p>
                  </li>
                </ul>
              </div>
              <div class="pricing_btn">
                <a href="#contact" class="button scroll">get started</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mt_30">
            <div class="pricing_box">
              <div class="pricing_name">
                <h4>Unlimited</h4>
              </div>
              <div class="pricing_rate">
                <span>contact us for the price</span>
              </div>
              <div class="pricing_list">
                <ul>
                  <li>
                    <span><i class="fal fa-check"></i></span>
                    <p>copy to 100+ accounts</p>
                  </li>
                  <li>
                    <span><i class="fal fa-check"></i></span>
                    <p>orders are copied within a second</p>
                  </li>
                  <li>
                    <span><i class="fal fa-check"></i></span>
                    <p>location (US, EUR, ASIA) by choice</p>
                  </li>
                </ul>
              </div>
              <div class="pricing_btn">
                <a href="#contact" class="button scroll">get started</a>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <p class="pt_40 pricing_P">
              <strong>Note</strong>: Please note that during Alpha testing
              CryptoCopyTrade.io is free. Early adopters will get a lifetime
              discount! If you’d like to contact us to join the testing
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- faq area -->
    <div class="faq_wrapper" id="faq">
      <div class="container">
        <h3 class="title">Faq</h3>
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="accordion" id="accordionExample">
              <div class="accordion_box accordion_box_one">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                >
                  Lorem Ipsum is simply?
                </button>
                <div
                  id="collapseOne"
                  class="collapse show"
                  data-parent="#accordionExample"
                >
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </div>
              <div class="accordion_box">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                >
                  Lorem Ipsum is simply?
                </button>
                <div
                  id="collapseTwo"
                  class="collapse"
                  data-parent="#accordionExample"
                >
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </div>
              <div class="accordion_box">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                >
                  Lorem Ipsum is simply?
                </button>
                <div
                  id="collapseThree"
                  class="collapse"
                  data-parent="#accordionExample"
                >
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </div>
              <div class="accordion_box accordion_box_last">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                >
                  Lorem Ipsum is simply?
                </button>
                <div
                  id="collapseFour"
                  class="collapse"
                  data-parent="#accordionExample"
                >
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- contact area -->
    <div class="contact_wrapper" id="contact">
      <div class="container">
        <h2 class="title">Contact Us</h2>
        <p class="contact_p">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim.
        </p>
        <div class="row">
          <div class="col-lg-4 col-md-5 mt_30">
            <div class="address_box_wrap">
              <div class="address_box">
                <h3>Get in touch with us</h3>
                <ul>
                  <li>
                    <span>Phone :</span>
                    <a href="tel:900888707123">+900 888 707 123</a>
                  </li>
                  <li>
                    <span>Email :</span>
                    <a href="mailto:name@yoursite.com">name@yoursite.com</a>
                  </li>
                  <li>
                    <span>Adress :</span>
                    <p>Pasar kambing 58 Suite X.110 Peterongan Semarang, USA</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-7 mt_30">
            <form action="#" class="contact_form">
              <div class="input_box">
                <input type="text" placeholder="Your name" />
              </div>
              <div class="input_box">
                <input type="email" placeholder="Your email" />
              </div>
              <div class="text_area">
                <textarea placeholder="Massage"></textarea>
              </div>
              <div class="contact_btn">
                <button type="submit" class="button">Send Massage</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>

  <!-- footer area -->
  <!-- footer area -->
  <footer>
    <div class="footer_wrapper">
      <div class="container">
        <div class="footer_brd"></div>
        <div class="row justify-content-between">
          <div class="col-md-4 mt_30">
            <a href="index.html" class="footer_logo">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </a>
            <p class="pt_30 logo_p">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </div>
          <div class="col-md-3 col-6 mt_30">
            <div class="footer_link_box">
              <h3>Company</h3>
              <ul class="footer_list">
                <li><a href="#">blog</a></li>
                <li><a href="#">Jobs</a></li>
                <li><a href="#">About us</a></li>
                <li><a href="#">About zig coin</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 col-6 mt_30">
            <div class="footer_link_box">
              <h3>Products</h3>
              <ul class="footer_list">
                <li><a href="#">Crypto Profit Sharing</a></li>
                <li><a href="#">Crypto Copy Trading</a></li>
                <li><a href="#">Crypto Signals</a></li>
                <li><a href="#">Zignaly Bridge</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-6 order-md-2 mt_60">
            <ul class="social_list">
              <li>
                <a href="#"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-instagram"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-youtube"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-telegram-plane"></i></a>
              </li>
              <li>
                <a href="#"><i class="fas fa-comments"></i></a>
              </li>
            </ul>
          </div>
          <div class="col-md-6 order-md-1 mt_60">
            <p class="copy_text">
              COPYRIGHT © 2018 – 2022 ZIGNALY. ALL RIGHTS RESERVED.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
  },
});
</script>
